/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../_metronic/_helpers";
import { baseURL, urlPortalKPI } from "../../app/service/API.js";
import axios from "axios";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  let role = localStorage.getItem('role')
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const gotoPortalKPI = async () => {
    let apiUrls = `${baseURL}/api/v1/user/getTokenKPI`;
    axios({
      method: "get",
      url: apiUrls,
    })
      .then(async (res) => {
        console.log(res);
        let token = res?.data?.data?.user_token
        let username = localStorage.getItem('PortalKPI_username')
        let channel = localStorage.getItem('PortalKPI_channel')
        let type = localStorage.getItem('PortalKPI_type')
        // let goUrl = `${urlPortalKPI}/${type}/${username}/${channel}/token=${token}`
           let goUrl = `${urlPortalKPI}/tcc/admin/Y2xhaW0=/token=${token}`
        // 'https://app-dev.kpi.co.th/tcc/admin/Y2xhaW0=/token=MTcxOTIyMDY0OWNsYWltX29ubGluZVRIRUNPTkNFUlQy'
     
        window.open(goUrl, '_blank');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        <li className="menu-section ">
          <h4 className="menu-text">MANAGE</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        <li
          className={`menu-item ${getMenuItemActive('/ClaimManagement')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/ClaimManagement">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">รายการเคลม</span>
          </NavLink>
        </li>
        {role == '1' && <li
          className={`menu-item ${getMenuItemActive('/UserManagement')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/UserManagement">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">จัดการผู้ใช้</span>
          </NavLink>
        </li>}
        {/* <li
          className={`menu-item ${getMenuItemActive('/ClaimManagement')}`}
          aria-haspopup="true"
          style={{display: "none !important"}}
        >
          <a className="menu-link" rel="noopener noreferrer"
            onClick={() => {
              gotoPortalKPI();
            }}>
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">รายการเคลม TICket</span>
          </a>
        </li> */}
      </ul>
    </>
  );
}
