import axios from "axios";
import { baseURL, baseURLV1 } from "../../../service/API";

export const LOGIN_URL = `${baseURLV1}/auth/login`;
export const ME_URL = `${baseURLV1}/auth/me`;
export const LOGOUT_URL = `${baseURLV1}/auth/logout`;


export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function getUserByToken() {
  let token = localStorage.getItem("token");

  return axios({
    method: "post",
    url: ME_URL,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      console.error("Error fetching user by token:", error);
      localStorage.clear();

      throw error;
    });
}

export function logout() {
  const token = localStorage.getItem("token");
  console.log('logout token', token)
  
  return axios({
    method: "post",
    url: LOGOUT_URL,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      console.error("Error fetching user by token:", error);
      localStorage.clear();

      throw error;
    });
}

// export function getUserByToken() {
//   // Authorization head should be fulfilled in interceptor.

//   let token = localStorage.getItem("token");
//   return axios({
//     method: "post",
//     url: ME_URL,
//     headers: {
//       Accept: "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   });
// }
